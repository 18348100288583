var app = new Vue({
  el: '#app',
  data: function () {
    return {
      tabIndex: '0', //tab默认
      tabList: [
        {
          name: '密码登录',
          type: '0'
        },
        {
          name: '验证码登录',
          type: '1'
        },
        {
          name: '扫码登录',
          type: '2'
        }
      ], //tab对象
      showPswVal: false, //显示密码
      accountVal: $.cookie('accountVal') || '',
      passWordVal: $.cookie('passWordVal') || '',
      mobileVal: '',
      validateVal: '',
      validateDisable: false, // 验证按钮是否失效
      checkValidateState: false,
      countDownNum: 60, // 倒计时总秒数
      timer: null,
      isSelected: false, //check
      loginError: ''
    }
  },
  created: function () { },
  mounted: function () { },
  //方法绑定
  methods: {
    // tab切换
    tabFun: function (type) {
      var _this = this
      _this.tabIndex = type
      _this.validateDisable = false
      _this.loginError = ''
      _this.countDownNum = 60
      _this.checkValidateState = false
      clearInterval(_this.timer)
    },
    // select设置默认动作
    selectFun: function () {
      this.isSelected = !this.isSelected
    },
    //密码隐藏
    switchPwd: function () {
      this.showPswVal = !this.showPswVal
    },
    checkLoginFn: function () {
      var _this = this
      _this.validateError = ''
      if (_this.tabIndex == 0) {
        if (!$.trim(_this.accountVal)) {
          _this.loginError = '手机号或账号不能为空'
          return false
        }
        var passwordVal = $.trim(_this.passWordVal)
        // var regPassword = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
        var regPassword = /^[0-9A-Za-z]{5,16}$/
        if (!$.trim(_this.passWordVal)) {
          _this.loginError = '密码不能为空'
          return false
        }
        if (regPassword.test(passwordVal)) {
          _this.loginError = ''
          return true
        } else {
          _this.loginError = '请输入正确的密码'
          return false
        }
      } else if (_this.tabIndex == 1) {
        if (_this.checkPhoneFn()) {
          if (!$.trim(_this.validateVal)) {
            _this.loginError = '请输入手机验证码'
            return false
          } else {
            _this.loginError = ''
            return true
          }
          // if (_this.checkValidateState) {
          //   _this.loginError = ''
          //   return true
          // } else {
          //   _this.loginError = '验证码错误'
          //   return false
          // }
        }
      }
    },
    loginFn: function () {
      var _this = this
      if (_this.tabIndex == 0) {
        if (_this.checkLoginFn()) {
          var accountData = {
            userType: 1, // 原本是0
            account: _this.accountVal,
            password: _this.passWordVal
          }
          // console.log(111);
          $.post(
            '/login/do',
            accountData,
            function (result) {
              if (result.code == '0') {
                _this.loginError = ''
                var expiresDate = new Date()
                expiresDate.setTime(
                  expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000
                )
                if (_this.isSelected) {
                  $.cookie('accountVal', _this.accountVal, {
                    path: '/',
                    expires: expiresDate,
                    // domain: document.domain.replace(/^[^\.]+\./, ''),
                    secure: false
                  })
                  $.cookie('passWordVal', _this.passWordVal, {
                    path: '/',
                    expires: expiresDate,
                    // domain: document.domain.replace(/^[^\.]+\./, ''),
                    secure: false
                  })
                }
                //  存储小能客服用的 userCode
                $.cookie('userCode', result.content.userCode, {
                  path: '/',
                  expires: expiresDate,

                  secure: false
                })
                //  存储小能客服用的 userCode
                $.cookie('phone', result.content.phone, {
                  path: '/',
                  expires: expiresDate,

                  secure: false
                })
                console.log('result.content.phone22', result.content.phone)
                // return
                if ($.cookie('fromUrl')) {
                  window.open($.cookie('fromUrl'), '_self')
                } else {
                  window.open('/', '_self')
                }
              } else {
                if (
                  /冻结|黑名单|错误|不存在|您登录的帐号|手机号或密码错误/.test(
                    result.msg
                  )
                ) {
                  toast(result.msg)
                } else {
                  toast('网络错误，请稍候重试')
                }
              }
            },
            'json'
          )
        }
      } else if (_this.tabIndex == 1) {
        // var authPhoneDto = {
        //   phone: _this.mobileVal,
        //   validateCode: _this.validateVal
        // }
        // var authPhoneDto = JSON.stringify(authPhoneDto)
        // $.post('/checkValidateCode', { authPhoneDto: authPhoneDto }, function(
        //   result
        // ) {
        //   result = JSON.parse(result)
        //   if (result.code == 0) {
        //     _this.checkValidateState = true
        //     _this.loginError = ''
        //   } else {
        //     _this.checkValidateState = false
        //     _this.loginError = '验证码错误'
        //     return false
        //   }
        // })
        if (_this.checkLoginFn()) {
        } else {
          return false
        }

        var userInfo = {
          account: _this.mobileVal,
          smsCode: _this.validateVal,
          sysfrom: '0',
          userType: 0
        }
        var userInfo = JSON.stringify(userInfo)
        $.post(
          '/sms',
          {
            userInfo: userInfo
          },
          function (result) {
            var result = JSON.parse(result)

            if (result.code == 0) {
              var expiresDate = new Date()
              expiresDate.setTime(
                expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000
              )
              //  存储小能客服用的 userCode
              $.cookie('userCode', result.content.userCode, {
                path: '/',
                expires: expiresDate,
                domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })
              //  存储小能客服用的 userCode
              $.cookie('phone', result.content.phone, {
                path: '/',
                expires: expiresDate,
                domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })
              $.post('/getUserA', function (result) {
                if (result.code == 0) {
                  _this.loginError = ''
                  // debugger
                  window.open($.cookie('fromUrl') || '/', '_self')
                }
              })
            } else {
              toast(result.msg)
            }
          }
        )
      }
    },
    checkAccountFn: function () {
      var _this = this
      if (!$.trim(_this.mobileVal)) {
        _this.loginError = '手机号或帐号不能为空'
        return false
      }
    },
    checkPhoneFn: function () {
      var _this = this
      var phoneReg = /^[1][0-9]{10}$/
      if (!$.trim(_this.mobileVal)) {
        _this.loginError = '请输入手机号'
        return false
      }
      if (phoneReg.test(_this.mobileVal)) {
        _this.loginError = ''
        return true
      } else {
        _this.loginError = '请输入正确的手机号'
        return false
      }
    },
    // 发送验证码
    sendValidateCode: function () {
      var _this = this
      if (_this.checkPhoneFn()) {
        $.post('/validate?account=' + _this.mobileVal, function (result) {
          var result = JSON.parse(result)
          if (result.code == 0) {
            _this.validateDisable = true
            _this.loginError = ''
            _this.countDown()
          } else {
            _this.loginError = '发送短信间隔时间太短，请稍候再发'
          }
        })
      }
    },
    //校验验证码
    // checkValidate: function() {
    //   var _this = this

    //   var authPhoneDto = {
    //     phone: _this.mobileVal,
    //     validateCode: _this.validateVal
    //   }
    //   var authPhoneDto = JSON.stringify(authPhoneDto)
    //   $.post('/checkValidateCode', { authPhoneDto: authPhoneDto }, function(
    //     result
    //   ) {
    //     result = JSON.parse(result)
    //     if (result.code == 0) {
    //       _this.checkValidateState = true
    //       _this.loginError = ''
    //     } else {
    //       _this.checkValidateState = false
    //       _this.loginError = '验证码错误'
    //     }
    //   })
    // },
    // 倒计时
    countDown: function () {
      var _this = this
      _this.timer = setInterval(function () {
        if (_this.countDownNum != 0) {
          _this.countDownNum--
        } else {
          _this.validateDisable = false
          _this.countDownNum = 60
          clearInterval(_this.timer)
        }
      }, 1000)
    }
  }
})

$(function () {
  var ref = ''
  if (document.referrer.length > 0) {
    ref = document.referrer
  }

  try {
    if (ref.length == 0 && opener.location.href.length > 0) {
      ref = opener.location.href
    }
  } catch (e) { }

  var reg = RegExp(/(login|register-2|personal-choice-method|new-pwd)/)
  if (!reg.exec(ref)) {
    var expiresDate = new Date()
    expiresDate.setTime(expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000)
    //  不包含login
    $.cookie('fromUrl', ref, {
      path: '/',
      expires: expiresDate,
      domain: document.domain.replace(/^[^\.]+\./, ''),
      secure: false
    })
  }
  // alert()

  // 二维码登录
  var qrcode = new QRCode(document.getElementById('qrcode'), {
    width: 134,
    height: 134
  })
  var timer
  $('.login-tab li:nth-child(1)').click(function () {
    clearInterval(timer)
  })
  $('.login-tab li:nth-child(2)').click(function () {
    clearInterval(timer)
  })
  $('.login-tab li:nth-child(3)').click(function () {
    function qrCode() {
      $.post('/loginQrCode', function (result) {
        var result = JSON.parse(result)
        if (result.code == 0) {
          var qrCodeVal = result.content
          qrcode.makeCode(qrCodeVal)
          timer = setInterval(function () {
            qrStatus(qrCodeVal)
          }, 3000)
        }
      })
    }

    qrCode()

    function qrStatus(qrCodeVal) {
      var queryMap = {
        qrCode: qrCodeVal
      }
      var queryMap = JSON.stringify(queryMap)
      $.post(
        '/loginQrStatus',
        {
          queryMap
        },
        function (result) {
          var result = JSON.parse(result)
          if (result.code == 0) {
            var status = result.content.status
            if (status == -1) {
              // qrCode();
              clearInterval(timer)
            } else if (status == 1) {
              $('#qrcode').hide()
              $('.qrcode1').show()
            } else if (status == 2) {
              var loginVo = result.content.loginVo.token
              var expiresDate = new Date()
              expiresDate.setTime(
                expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000
              ) //只能这么写，1表示1秒钟

              $.cookie('token', loginVo, {
                path: '/',
                expires: expiresDate,
                domain: document.domain.replace(/^[^\.]+\./, ''),
                secure: false
              })
              if ($.cookie('token')) {
                $.post(
                  '/getUserInfo',
                  {
                    token: $.cookie('token')
                  },
                  function (result) {
                    if (result.code == 0) {
                      // console.log(result.content);
                      $('#login-bar .top-nav .top-nav-login .name').html(
                        result.content.phone
                          ? result.content.phone
                          : result.content.caption
                      )
                      $(
                        '#nav-container .nav-main .view1-nav .nav-btn1130 .name .p'
                      ).html(
                        result.content.phone
                          ? result.content.phone
                          : result.content.caption
                      )
                      var expiresDate = new Date()
                      expiresDate.setTime(
                        expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000
                      )
                      $.cookie('unick', result.content.phone, {
                        path: '/',
                        expires: expiresDate,
                        domain: document.domain.replace(/^[^\.]+\./, ''),
                        secure: false
                      })
                      $.cookie('phone', result.content.phone, {
                        path: '/',
                        expires: expiresDate,
                        domain: document.domain.replace(/^[^\.]+\./, ''),
                        secure: false
                      })
                      window.open($.cookie('fromUrl'), '_self')
                    }
                  }
                )
              }
            }
          }
        }
      )
    }
  })

  // 微信登录
  function weixinFun() {
    var obj = new WxLogin({
      self_redirect: false,
      id: 'login_container',
      // appid: "wx48229ef0f1156d6a",
      appid: 'wx8490ee881fafddf5', //www
      scope: 'snsapi_login',
      // redirect_uri: encodeURIComponent("http://check.fuqim.com/login"),
      // redirect_uri: encodeURIComponent("localhost:8087/login"),
      // redirect_uri: encodeURIComponent("https://sp.fuqim.com/login"),
      redirect_uri: encodeURIComponent('https://www.fuqimao.com/login'),
      state: 'fuqimweixinlogin',
      style: 'black'
    })
  }
  weixinFun()
  $('.weixinloginBtn').click(function () {
    $('.weixinlogin').show()
  })
  $('.weixinloginClose').click(function () {
    $('.weixinlogin').hide()
  })
  // 三方登录获取参数
  function GetQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) return unescape(r[2])
    return null
  }
  var code = GetQueryString('code')
  if (window.location.hash) {
    var access_token = window.location.hash.split('=')[1].split('&')[0]
  }
  // console.log(111,access_token);
  var state = GetQueryString('state')
  console.log(state)
  // console.log(state);
  if (state) {
    accessTokenwxFun(code)
  } else if (access_token) {
    // console.log(access_token)
    accessTokenqqFun(access_token)
  }
  //获取微信的 unionid
  function accessTokenwxFun(code) {
    var queryMap = {
      code: code
    }
    var queryMap = JSON.stringify(queryMap)
    $.post(
      '/accessTokenwx',
      {
        queryMap
      },
      function (result) {
        var result = JSON.parse(result)
        if (result.code == 0) {
          console.log(result)
          var unionid = result.content.unionid
          var openid = result.content.openid
          if (unionid) {
            verFun(openid, 0, unionid)
          }
        }
      }
    )
  }

  //获取qq的 unionid
  function accessTokenqqFun(access_token) {
    var queryMap = {
      accessToken: access_token
    }
    var queryMap = JSON.stringify(queryMap)
    $.post(
      '/accessTokenqq',
      {
        queryMap
      },
      function (result) {
        var result = JSON.parse(result)
        if (result.code == 0) {
          // console.log(result);
          var unionid = result.content.unionid
          var openid = result.content.openid
          if (unionid) {
            verFun(openid, 1, unionid)
          }
        }
      }
    )
  }

  // 校验 绑定用户
  function verFun(openid, type, unionid) {
    var platformDto = {
      // "openId": openid,
      platformType: type,
      unionId: unionid
    }
    var platformDto = JSON.stringify(platformDto)
    // 绑定校验
    $.post(
      '/getUnion',
      {
        platformDto
      },
      function (result) {
        var result = JSON.parse(result)
        if (result.code == 0) {
          // console.log(result);
          if (result.content) {
            var loginVo = result.content.token
            var expiresDate = new Date()
            expiresDate.setTime(expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000) //只能这么写，1表示1秒钟
            $.cookie('token', loginVo, {
              path: '/',
              expires: expiresDate,
              domain: document.domain.replace(/^[^\.]+\./, ''),
              secure: false
            })
            if ($.cookie('token')) {
              $.post(
                '/getUserInfo',
                {
                  token: $.cookie('token')
                },
                function (result) {
                  if (result.code == 0) {
                    // console.log(result.content);
                    $('#login-bar .top-nav .top-nav-login .name').html(
                      result.content.phone
                        ? result.content.phone
                        : result.content.caption
                    )
                    $(
                      '#nav-container .nav-main .view1-nav .nav-btn1130 .name .p'
                    ).html(
                      result.content.phone
                        ? result.content.phone
                        : result.content.caption
                    )
                    // window.open($.cookie('fromUrl'), '_self')
                    window.open($.cookie('fromUrl'), '_self')
                  }
                }
              )
            }
          } else {
            window.location.href =
              '/third-party?id=' + unionid + '&type=' + type
          }
        }
      }
    )
  }
})
